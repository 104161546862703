import React from 'react'
import PropTypes from 'prop-types'
import { Title, Subtitle } from '../shared/text-styled'
import * as S from './styled'

const DynamicPage = ({ title, description, html }) => (
  <S.ContainerPage>
    <Title>{title}</Title>
    <Subtitle>{description}</Subtitle>
    <S.InnerHTML>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </S.InnerHTML>
  </S.ContainerPage>
)

DynamicPage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  html: PropTypes.string,
}

export default DynamicPage
