import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from 'components/layout'
import SEO from '../components/seo'
import DynamicPage from '../components/dynamic-page'

const Page = ({ data, location, pageContext }) => {
  const StaticPage = data.wordpressPage
  const { title, subtitle, description, canonical } = StaticPage.acf

  return (
    <Layout location={location} pageContext={pageContext} crumbLabel={StaticPage.title}>
      <SEO
        title={title || StaticPage.title}
        description={description}
        canonical={canonical}
      />
      <DynamicPage title={StaticPage.title} subtitle={subtitle} html={StaticPage.content} />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      acf {
        canonical
        description
        title
        subtitle
      }
    }
    site {
      id
      siteMetadata {
        title
        subtitle
      }
    }
  }
`
