import styled from 'styled-components'
import media from 'styled-media-query'
import { ContainerDasa } from '../shared/container'
import { alvTokens } from '@dasa-health/alma-tokens'

export const ContainerPage = styled(ContainerDasa)`
  margin-bottom: 3.2rem;

  h1 {
    font-size: 24px;
    color: ${props => props.theme.colors.brand.primary[10]};
    max-width: unset;
    margin-bottom: 1.4rem;

    ${media.lessThan('medium')`
      font-size: 20px;
    `}
  }

  h2 {
    font-size: 24px;
    color: var(--pink);
    margin: 4rem 0 1rem;
    word-wrap: break-word; 
  }

  h3 {
    font-size: 20px;
    color: var(--pink);
    margin: 4rem 0 1rem;
  }

  p {
    margin: 0;
  }
`

export const InnerHTML = styled.div`
  font-size: 14px;
  color: ${alvTokens.ColorPaletteGray30};
  font-weight: 400;
  line-height: 170%;
  word-wrap: break-word; 
`
